<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import DatePicker from "vue2-datepicker";
import 'vue2-datepicker/locale/pt-br';
import SettingService from '@/services/settings'
import { VMoney } from 'v-money'

export default {
  page: {
    title: "Campanha - Entrega Grátis",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, DatePicker },
  directives: { money: VMoney },
  data() {
    return {
      title: "Campanha - Entrega Grátis",
      settings: {},
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false,
      }
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      let loader = this.$loading.show();

      try {
        const settingsResult = await SettingService.getSettings();
        this.settings = settingsResult.data.freeDelivery || {};

        this.formatData();

        loader.hide();
      } catch (err) {
        loader.hide();
        this.$toast.error('Ocorreu um erro ao carregar as configurações');
      }
    },
    async save() {
      let loader = this.$loading.show();

      try {
        const data = {
          ...this.settings,
          minimumSaleValue: Number(this.settings.minimumSaleValue.toString().replace('.', '').replace(',', '.')),
          limitToNotify: Number(this.settings.limitToNotify.toString().replace('.', '').replace(',', '.')),
        }

        await SettingService.update({ freeDelivery: data });

        const settingsResult = await SettingService.getSettings();
        this.settings = settingsResult.data.freeDelivery || {};

        this.$toast.open('Configuração atualizada com sucesso');

        this.formatData();

        loader.hide();
      } catch (err) {
        loader.hide();
        this.$toast.error('Ocorreu um erro ao salvar as configurações');
      }
    },
    formatData() {
      if (this.settings.minimumSaleValue) {
        this.settings.minimumSaleValue = this.settings.minimumSaleValue.toFixed(2).replace('.', ',');
      }

      if (this.settings.startDate) {
        this.settings.startDate = new Date(this.settings.startDate);
      }

      if (this.settings.endDate) {
        this.settings.endDate = new Date(this.settings.endDate);
      }

      if (this.settings.limitToNotify) {
        this.settings.limitToNotify = (this.settings.limitToNotify || 0).toFixed(2).replace('.', ',');
      }
    },
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <h4 class="mb-0 font-size-18">{{title}}</h4>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <form>
          <div class="row">
            <div class="col-lg-6 col-sm-12">
              <div class="row">
                <div class="col-6">
                  <div class="form-group mb-3">
                    <label>Período de</label>
                    <date-picker v-model="settings.startDate" :first-day-of-week="1" format="DD/MM/YYYY" lang="pt-br"></date-picker>
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group mb-3">
                    <label>até</label>
                    <date-picker v-model="settings.endDate" :first-day-of-week="1" format="DD/MM/YYYY" lang="pt-br"></date-picker>
                  </div>
                </div>
              </div>
              
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label for="productStock">Valor Pedido</label>

                    <input
                      id="saleValue"
                      name="saleValue"
                      type="tel"
                      class="form-control"
                      v-money="money"
                      v-model="settings.minimumSaleValue"
                    />
                  </div>
                </div>

                <div class="col-sm-4">
                  <div class="form-group">
                    <label for="productStock">% Notificação</label>

                    <input
                      id="limitToNotify"
                      name="limitToNotify"
                      type="tel"
                      class="form-control"
                      v-money="money"
                      v-model="settings.limitToNotify"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-2 align-items-center">
            <div class="col-sm-6">
              <button type="button" class="btn btn-primary btn-rounded mb-2" @click="save">
                <i class="mdi mdi-check mr-1"></i> Salvar Configuração
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </Layout>
</template>
